import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DevicesService } from 'src/app/services/devices/devices.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-manage-devices',
  templateUrl: './manage-devices.component.html',
  styleUrls: ['./manage-devices.component.css']
})
export class ManageDevicesComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  devices: any[] = [];
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isLoading: boolean = false;
  form!: FormGroup;
  submitted: boolean = false;
  public row_id:string='';
  public device_id:string='';
  @ViewChild('UpdateDevice') modalElement!: ElementRef;

  constructor(
    private devicesService: DevicesService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ){
    this.form = this.formBuilder.group({
      new_device_id:['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.fetchAll();
  }

  fetchAll():void{
    this.devicesService.getDevices().subscribe({
      next: (response: ApiResponse) => {
        this.isLoading = false;
        this.devices = response.data;
      },
      error: (error) => {
        this.isLoading = false;
        this.errorMessage=error;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchAll();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  openModal(row_id:string,device_id:string){
    this.row_id=row_id;
    this.device_id=device_id;

    // Use patchValue to set the value of new_device_id on the form control
    this.form.patchValue({
      new_device_id: device_id
    });
    
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('show');
    }
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');
    }
  }

  updateDevice(){
    this.submitted = true;
    this.isLoading=true;

    if (this.form.valid) {
      this.devicesService.updateDevice(this.form.value,this.row_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isLoading=false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
            // Refresh to device list
            this.fetchAll();
          }else{
            this.submitted = false;
            this.isLoading=false;
            this.errorMessage=response.message;
            this.closeModal();
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isLoading=false;
          this.errorMessage=error;
          this.closeModal();
        }
      });
    }else{
      this.isLoading=false;
    }
  }

  delete(event: Event,rowId:string):void{
    event.preventDefault();
    this.isLoading=true;
    
    let text = "Are you sure you want to delete this device?";
    if (confirm(text) == true) {
      this.devicesService.deleteDevice(rowId)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.isLoading=false;
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
            // Refresh to device list
            this.fetchAll();
          }else{
            this.submitted = false;
            this.isLoading=false;
            this.errorMessage=response.message;
            this.closeModal();
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isLoading=false;
          this.errorMessage=error;
          this.closeModal();
        }
      });
    } 
  }
}
