import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { DevicesService } from 'src/app/services/devices/devices.service';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.css']
})
export class AddDeviceComponent implements OnInit{
  form!: FormGroup;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;
  public userId: string | null = null;
  public userFullName: string | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
    private devicesService: DevicesService,
  ){
    this.form = this.formBuilder.group({
      device_id: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.userId = param['get']('user_id')
      this.userFullName = param['get']('name')
    })

    if(!this.userId){
      //Navigate back to users list with error
      this.globalService.setGlobalErrorMessage("Unable to select user for adding device. Please try again!");
      this.router.navigate(['settings/users-list']);
    }
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;

    if (this.form.valid) {
      const formData = {
        ...this.form.value,
      };

      this.devicesService.addDevice(formData,this.userId).subscribe({
          next: (response) => {
            if(response.status==='success'){

              this.submitted=false;
              this.isProcessing = false;
              this.globalService.setGlobalSuccessMessage(response.message);
            
              // Redirect back to users list
              this.router.navigate(['settings/users-list']);
            }else if(response.status==='error'){
              this.submitted=false;
              this.isProcessing = false;
              this.globalService.setGlobalErrorMessage(response.message);
            }else{
              this.submitted=false;
              this.isProcessing = false;
              this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
            }
        },
        error: (error) => {
          this.isProcessing = false;
          this.errorMessage=error;
        }
      });
    }else{
      this.isProcessing = false;
    }
  }
}
