 <!-- Content Header (Page header) -->
 <section class="content-header">
    <div class="content-header">
       <div class="container-fluid">
         <div class="row mb-2">
           <div class="col-sm-6">
               <h3>Received Vehicle Details</h3>
           </div>
           <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
               <li class="breadcrumb-item"><a routerLink="/vehicle-transfer-history"><i class="fas fa-sd-card"></i>&nbsp;Vehicle Transfer History</a></li>
               <li class="breadcrumb-item active">Received Vehicle Details</li>
             </ol>
           </div><!-- /.col -->
         </div><!-- /.row -->
       </div><!-- /.container-fluid -->
   </div>
   </section>
     <!-- /.content-header -->

    <!--Loading Spinner-->
    <div *ngIf="isLoading">
      <app-spinner></app-spinner>
    </div>

   <section class="content">
   <div class="container-fluid">
   <div class="card">
       <div class="card-header">
         <h3 class="card-title">Received Vehicle Details</h3>
         <!--Error Message-->
         <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
          </div>
          <!--Success Message-->
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
          </div>
          <div style="float: right;" *ngIf="photos && photos.length > 0 && userPermissions.includes('del-vhcl-reloc-photos')">
            <a (click)="openDeletePhotosModal()" class="btn btn-success pull-left" class="btn btn-danger"><i
                class="fa fa-trash" aria-hidden="true">&nbsp;Delete Photos&nbsp;</i></a>
          </div>
       </div>
       <!-- /.card-header -->
       <div class="card card-solid">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-6">
                <h3 class="d-inline-block d-sm-none">Received Vehicle Details</h3>
                <div class="col-12">
                    <div id="photoCarousel" class="carousel slide" data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li *ngFor="let photo of photos; let i = index" [class.active]="i === 0" data-target="#photoCarousel" [attr.data-slide-to]="i"></li>
                        </ol>
                        
                        <div class="carousel-inner">
                            <div *ngFor="let photo of photos; let i = index" class="carousel-item" [class.active]="i === 0">
                                <div class="carousel-content-wrapper position-relative">
                                    <img [src]="photo.image_url" class="d-block w-100" alt="Vehicle Photo" />
                                </div>
                            </div>
                        </div>
                    
                        <a class="carousel-control-prev" href="#photoCarousel" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#photoCarousel" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>           
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <h3 class="my-3">{{vehicle?.data?.make}} {{vehicle?.data?.model}}</h3>
             
                <form method="post" action="#">
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Chassis No.:</label>
                                <input type="text" class="form-control" value="{{vehicle.data.chassis_no}}" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Engine CC:</label>
                                <input type="text" class="form-control" value="{{vehicle.data.engine_cc}}" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Engine No.:</label>
                                <input type="text" class="form-control" value="{{vehicle.data.engine_no}}" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-4">
                          <div class="form-group">
                              <label>Transmission:</label>
                              <input type="text" class="form-control" value="{{vehicle.data.transmission}}" readonly>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group">
                              <label>Body Type:</label>
                              <input type="text" class="form-control" value="{{vehicle.data.body_type}}" readonly>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group">
                              <label>Drive:</label>
                              <input type="text" class="form-control" value="{{vehicle.data.drive}}" readonly>
                          </div>
                      </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Fuel:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.fuel}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Mileage:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.mileage}}" readonly>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Colour:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.colour}}" readonly>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>Year:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.year}}" readonly>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>Weight:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.weight}}" readonly>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>CBM:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.cbm}}" readonly>
                      </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>From:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.from_location}}" readonly>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>To:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.received_at}}" readonly>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>Transferred On:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.transferred_on| date: 'dd-MM-yyyy h:mm a'}}" readonly>
                      </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>Transferred By:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.transferred_by}}" readonly>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>Received On:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.received_on | date: 'dd-MM-yyyy h:mm a'}}" readonly>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label>Received By:</label>
                          <input type="text" class="form-control" value="{{vehicle.data.received_by}}" readonly>
                      </div>
                  </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-12">
                        <h6><b>Available Spare Parts</b></h6>
                        <ul *ngFor="let spare_part of spare_parts">
                            <li>{{spare_part.name}}</li>
                        </ul>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Comment:</label>
                            <input type="text" class="form-control" value="{{vehicle.data.comment}}" readonly>
                        </div>
                    </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
       <!-- /.card-body -->
   </div>
   </div>
   </section>

   <div class="modal fade" #DeletePhotosModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Photos</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!--Error message-->
        <div class="alert alert-danger" role="alert" *ngIf="delPhotosPopupErrorMessage">
            {{delPhotosPopupErrorMessage}}
        </div>
        <table class="table" *ngFor="let photo of photos">
          <td>
            <img src="{{photo.image_url}}" height="70" width="120"/>
          </td>
          <td>
            <input type="checkbox" class="form-control" [(ngModel)]="photo.checked" (change)="checkboxChange($event, photo.image_url)" id="vehicle" name="photo">
          </td>
        </table>
        <div class="d-flex justify-content-end mr-4 mb-3">
          <div class="btn btn-danger btn-sm btn-flat" (click)="deletePhotos()">
            <i class="fas fa-trash fa-sm mr-2"></i>
            Delete
          </div>
        </div>        
      </div>
    </div>
</div>