import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private httpClient: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

   /**
   * Get authorized devices
   * @returns Array
   */
   getDevices(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/devices`;

    return this.httpClient.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Authorize new device
   * @param formData
   * @returns array
   */
  addDevice(formData:any,userId:any):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/devices/add/${userId}`;

    return this.httpClient.post(apiURL,formData, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Update Device
   * @param form_data 
   * @param row_id 
   * @returns array
   */
  updateDevice(form_data:any,row_id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/devices/update/${row_id}`;

    return this.httpClient.put(apiURL,form_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Delete Device
   * @param form_data 
   * @param row_id 
   * @returns array
   */
  deleteDevice(row_id: string|null):Observable<any>{

    let apiURL:string=`${this.baseURL}/v1/devices/delete/${row_id}`;

    return this.httpClient.delete(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
