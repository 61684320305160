import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class VehicleTransferHistoryService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

   /**
   * Get vehicle transfer history
   * @returns Array
   */
   getVehicleTransferHistory(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-vehicle-transfer-history`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Filter vehicle transfer history by date
   */
   filterByDate(startDate:any,endDate:any): Observable<ApiResponse>{
    const formData = new FormData();
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);

    let apiURL:string=`${this.baseURL}/v1/filter-vehicle-transfer-history-by-date`;
    
    return this.http.post<ApiResponse>(apiURL,formData).pipe(catchError(this.errorHandler.handleError));
  }
}
