import { Component, OnInit } from '@angular/core';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { StockService } from 'src/app/services/stock/stock.service';
import { LocationService } from 'src/app/services/locations/location.service';
import { BranchService } from 'src/app/services/branches/branch.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-all-available-stock',
  templateUrl: './all-available-stock.component.html',
  styleUrls: ['./all-available-stock.component.css']
})
export class AllAvailableStockComponent implements OnInit{
  public errorMessage: string | null = null;
  public userPermissions: string[] = [];
  vehicles: any[] = [];
  searchText: string = '';
  locations: any[] = [];
  branches: any[] = [];
  public isInProgress: boolean = false;
  rowCount: number=0;

  constructor(
    private stockService:StockService,
    private locationService:LocationService,
    private branchService:BranchService,
    private authService: AuthService
  ){}

  ngOnInit(): void {
    this.isInProgress=true;
    this.userPermissions=this.authService.getPermissions();
    this.fetchAllAvailableStock();
    this.fetchLocations();
  }

  fetchAllAvailableStock():void{
    this.stockService.getAllAvailableStock()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.rowCount = this.vehicles.length;
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.rowCount =0;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  //Load models & grades
  onSelectChange(target: EventTarget|null,option:string) {
    if (target instanceof HTMLSelectElement) {

      if(option==='location'){
        const locationId = target.value;
      
        if(locationId!=='' && locationId!==undefined){
          this.isInProgress=true;
          //Save Location ID in session for use when 'All' option is selected on branches
          sessionStorage.setItem("locationId", locationId);

          this.fetchBranchesByLocation(locationId);
          this.fetchStockByLocation(locationId);
        }else{
          this.isInProgress=true;
          this.branches = []; // Clear the branches
          this.fetchAllAvailableStock();
        }
      }else{
        this.isInProgress=true;
        const branchId = target.value;
      
        if(branchId!=='' && branchId!==undefined){
          this.fetchStockByBranch(branchId);
        }else{
          const locationId =sessionStorage.getItem("locationId");
          this.fetchStockByLocation(locationId!);
        }
      }
    }
  }

  fetchLocations():void{
    this.locationService.getAllLocations().subscribe((response: ApiResponse) =>this.locations = response.data);
  }

  fetchBranchesByLocation(locationId: string):void{
    this.branchService.getBranchesByLocation(locationId).subscribe((response: ApiResponse) =>this.branches = response.data);
  }

  fetchStockByLocation(locationId: string):void {
    this.stockService.filterAvailableStockByLocation(locationId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.rowCount = this.vehicles.length;
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
           this.vehicles=[];
           this.rowCount =0;
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  fetchStockByBranch(branchId: string):void {
    this.stockService.filterAvailableStockByBranch(branchId)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.rowCount = this.vehicles.length;
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
          this.vehicles=[];
          this.rowCount =0;
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }
}
