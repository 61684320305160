import { Component, OnInit } from '@angular/core';
import { AssignUnitsToBlService } from 'src/app/services/suppliers/assign-units-to-bl.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GlobalService } from 'src/app/services/shared/global.service';

@Component({
  selector: 'app-assign-units-to-bl',
  templateUrl: './assign-units-to-bl.component.html',
  styleUrls: ['./assign-units-to-bl.component.css']
})
export class AssignUnitsToBlComponent implements OnInit{
  public userPermissions: string[] = [];
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public warningMessage: string | null = null;
  vehicles: any[] = [];
  searchText: string = '';
  public isInProgress: boolean = false;
  locations: any[] = [];
  bills_of_lading: any[] = [];
  selectedBL: string='';
  selectedVehicles: any[] = []; // Array to store selected vehicles

  constructor(
    private assignUnitsToBlService: AssignUnitsToBlService,
    private authService: AuthService,
    private globalService: GlobalService,

  ){}

  ngOnInit(): void {
    this.isInProgress=true;
    this.fetchUnits();
    this.fetchBLs();
    this.userPermissions=this.authService.getPermissions();
  }

  fetchUnits():void{
    this.assignUnitsToBlService.getUnitsPendingBLAssignment()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

  fetchBLs():void {
    this.assignUnitsToBlService.getActiveBLs()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.bills_of_lading = response.data
          this.isInProgress=false;
          this.errorMessage=null;
        }else if(response.status==='no_data'){
          this.errorMessage=response.message;
          this.isInProgress=false;
          this.bills_of_lading = []; // Clear the bills of lading
        }else{
          this.errorMessage="Unable to load BLs. Please try again or consult your system administrator!";
          this.isInProgress=false;
          this.bills_of_lading = []; // Clear the bills of lading
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
        this.bills_of_lading = []; // Clear the bills of lading
      }
    });
  }

  // Function to handle checkbox change event
  checkboxChange(event: any, item: any) {
    if (event.target.checked) {
      // Add checked checkbox to the array
      this.selectedVehicles.push(item);
    } else {
      // Remove unchecked checkbox from the array
      const index = this.selectedVehicles.indexOf(item);
      if (index !== -1) {
        this.selectedVehicles.splice(index, 1);
      }
    }
  }

  assignUnitsToBL(): void {
    const bl_number = this.selectedBL;
  
    if(bl_number=="" || bl_number==undefined){

      this.errorMessage="Please select BL to proceed!";
    
    }else if(this.selectedVehicles.length===0){

      this.errorMessage="Please select unit(s) to assign to BL!";

    }else{
      this.errorMessage=null;
      this.isInProgress=true;
      this.assignUnitsToBlService.assignUnitsToBL(bl_number, this.selectedVehicles)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.selectedBL='';
            this.globalService.setGlobalSuccessMessage(response.message);
            //Clear vehicles array
            this.selectedVehicles.length = 0;
            this.isInProgress=true;
            // Refresh list to remove checks
            this.fetchUnits();
            //Reload BLs
            this.fetchBLs();
          }else if(response.status==='warning'){
            this.warningMessage=response.message;
            this.isInProgress=false;
          }else{
            this.errorMessage=response.message;
            this.isInProgress=false;
          }
        },
        error: (error) => {
          // Handle the error
          console.log(error);
          this.isInProgress=false;
          this.errorMessage="An Error occurred while assigning unit(s) to BL. Please try again!";
        }
      });
    }
  }

  delete(vehicle_id: string, make: string, model:string,chassis_no:string){
    let prompt = "Are you sure you want to delete "+make+" "+model+" with chassis number "+chassis_no+"?";

    if (confirm(prompt) == true) {
      this.isInProgress=true;
      this.assignUnitsToBlService.deleteUnit(vehicle_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
            this.isInProgress=true;
            // Refresh list
            this.fetchUnits();
          }else if(response.status==='warning'){
            this.warningMessage=response.message;
            this.isInProgress=false;
          }else{
            this.errorMessage=response.message;
            this.isInProgress=false;
          }
        },
        error: (error) => {
          // Handle the error
          console.log(error);
          this.isInProgress=false;
          this.errorMessage="An error occurred while deleting unit with chassis number "+chassis_no+". Please try again or consult your system administrator!";
        }
      });
    }
  }
}
