import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StockService } from 'src/app/services/stock/stock.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sold-cars',
  templateUrl: './sold-cars.component.html',
  styleUrls: ['./sold-cars.component.css']
})
export class SoldCarsComponent implements OnInit{
  public userPermissions: string[] = [];
  public errorMessage: string | null = null;
  vehicles: any[] = [];
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  public isInProgress: boolean = false;
  rowCount: number=0;
  form!: FormGroup;
  submitted: boolean = false;
  @ViewChild('ReturnSoldVehicleModal') modalElement!: ElementRef;
  public vehicle_id:string='';
  vehicle:any;
  branches: any[] = [];

  constructor(
    private stockService:StockService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
  ){
    this.form = this.formBuilder.group({
      returned_to:['',Validators.required],
      date_returned:['',Validators.required],
      comment:['']
    });
  }

  ngOnInit(): void {
    this.isInProgress=true;
    this.userPermissions=this.authService.getPermissions();
    this.fetchSoldCars();
  }

  fetchSoldCars():void{
    this.stockService.getSoldCars()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.rowCount = this.vehicles.length;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.rowCount =0;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

  openModal(vehicle_id:string){
    this.vehicle_id=vehicle_id;

    this.getVehicleDetails(this.vehicle_id);
  }

  closeModal():void {
    if (this.modalElement) {
      (<any>$(this.modalElement.nativeElement)).modal('hide');
    }
  }

  /**
   * Get single vehicle info
   * @param vehicle_id 
   */
  getVehicleDetails(vehicle_id: string): void {
    this.stockService.getVehicleDetails(vehicle_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicle = response.data;
          this.getBranches();
        }else if(response.status==='no_data'){
          this.errorMessage=response.message;
          this.isInProgress=false;
       }else{
         this.errorMessage="Sorry, a problem was encountered while loading vehicle details. Please refresh the page and try again!";
         this.isInProgress=false;
       }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  getBranches():void{
    this.stockService.fetchBranchesWithLocation()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.branches = response.data;
          this.isInProgress=false;
          //Open modal
          if (this.modalElement) {
            (<any>$(this.modalElement.nativeElement)).modal('show');
          }
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while loading branches. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  /**
   * Restock sold vehicle
   */
  returnSold(){
    this.submitted = true;
    this.isInProgress=true;

    if (this.form.valid) {

      const formData = {
        ...this.form.value,
      };

      this.stockService.returnSoldVehicle(formData,this.vehicle_id)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.submitted = false;
            this.errorMessage=null;
            this.form.reset();
            this.globalService.setGlobalSuccessMessage(response.message);
            this.closeModal();
            // Refresh list
            this.fetchSoldCars();
          }else{
            this.submitted = false;
            this.isInProgress=false;
            this.errorMessage=response.message;
            this.closeModal();
          }
        },
        error: (error) => {
          this.submitted = false;
          this.isInProgress=false;
          this.errorMessage=error;
          this.closeModal();
        }
      });
    }else{
      this.isInProgress=false;
    }
  }
}
