import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseURL = environment.apiURL;
  private permissions: string[] = [];
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient,public router: Router) { }

  login(username: string, password: string): Observable<boolean> {
    return this.http.post<{token: string}>(this.baseURL+'/login', {username: username, password: password})
      .pipe(
        map((response:any) => {
          //Set token
          if(response.token){
            sessionStorage.setItem('accessToken', response.token);
          }
          
          //Set user info
          let user_data=JSON.stringify(response);

          const obj_user = JSON.parse(user_data, function (_key,value) {
            return value;
          });
        
          sessionStorage.setItem('userID', obj_user.user.id);
          sessionStorage.setItem('userFullName', obj_user.user.name);
          sessionStorage.setItem('userEmail', obj_user.user.email);
          sessionStorage.setItem('userPermissions', JSON.stringify(response.permissions));
          sessionStorage.setItem('dashBoard', response.dashboard);
    
          return true;
        })
      );
  }

  getToken() {
    return sessionStorage.getItem('accessToken');
  }

  logout(){
    return this.http.post(this.baseURL+"/logout",this.httpOptions);
  }

  public get isLoggedIn(): boolean {
    let is_logged_in:boolean=false;
    let accessToken=sessionStorage.getItem('accessToken');

    if(accessToken!==null){
      is_logged_in=true;
    }
    
    return is_logged_in;
  }

  getPermissions() {
    let permissionsJson = sessionStorage.getItem('userPermissions');
    this.permissions=permissionsJson ? JSON.parse(permissionsJson) : null;

    return this.permissions;
  }

  getDashboard() {
    return sessionStorage.getItem('dashBoard');
  }
}