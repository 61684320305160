import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-dashboard-switcher',
  templateUrl: './dashboard-switcher.component.html',
  styleUrls: ['./dashboard-switcher.component.css']
})
export class DashboardSwitcherComponent implements OnInit{
  public dashboard:string|null='';

  constructor(
    private auth: AuthService
  ){}

  ngOnInit(): void {
    this.dashboard = this.auth.getDashboard();
  }
}
