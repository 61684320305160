import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private baseURL = environment.apiURL;

  constructor(private http: HttpClient, private errorHandler: ConfigErrorHandler) { }

  /**
   * Count vehicles at CFS
   * @returns Array
   */
  public countUnitsAtCFS():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/dashboard/count-units-at-cfs`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get total stock by location
   * @returns Array
   */
  public totalStockByLocation():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/dashboard/get-total-stock-by-location`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Count available stock by make
   * @returns Array
   */
  public countAvailableStockByMake():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/dashboard/count-available-stock-by-make`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get branches with locations
   * @returns Array
   */
  public getBranches():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/shared/branches-with-location`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Count available stock by make for branch
   * @returns Array
   */
  public countAvailableStockByMakeForBranch(branchId: string|null):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/dashboard/count-available-stock-by-make-for-branch/${branchId}`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get unpaid duties by consignee
   * @returns Array
   */
  public getUnpaidDutiesByConsgnee():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/dashboard/get-unpaid-duties-total-by-consignee`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Count available stock by model
   * @returns Array
   */
  public countAvailableStockByModel(make_id:string,branchId:string|null):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/dashboard/count-available-stock-by-model/${make_id}/${branchId}`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Gets units under the selected model
   * @returns Array
   */
  public getUnitsByModel(model_id:string,branchId:string|null):Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/dashboard/get-units-by-model/${model_id}/${branchId}`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }
}
