import { Component, OnInit } from '@angular/core';
import { VehicleTransferHistoryService } from 'src/app/services/vehicles/vehicle-transfer-history.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-vehicle-transfer-history',
  templateUrl: './vehicle-transfer-history.component.html',
  styleUrls: ['./vehicle-transfer-history.component.css']
})
export class VehicleTransferHistoryComponent implements OnInit{
  public errorMessage: string | null = null;
  vehicles: any[] = [];
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  public isLoading: boolean = false;
  form!: FormGroup;
  submitted: boolean = false;

  constructor(
    private vehicleTransferHistoryService: VehicleTransferHistoryService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
  ){
    this.form = this.formBuilder.group({
      date_from: ['', Validators.required],
      date_to: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.fetchVehicleTransferHistory();
  }

  fetchVehicleTransferHistory():void{
    this.vehicleTransferHistoryService.getVehicleTransferHistory()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isLoading=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }

  filterByDate(){
    this.submitted=true;
    this.isLoading = true;

    if (this.form.valid) {
      this.vehicleTransferHistoryService.filterByDate(this.form.value.date_from,this.form.value.date_to)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.isLoading=false;
            this.vehicles = response.data;
            this.page = 1; // Reset to page 1 after applying filter
          }else if(response.status==='no_data'){
            this.isLoading=false;
            this.vehicles=[];
            this.globalService.setGlobalWarningMessage(response.message);
          }else{
            this.isLoading=false;
            const errorMessage="Sorry, a problem was encountered while filtering vehicle transfer history. Please try again!";
            this.globalService.setGlobalErrorMessage(errorMessage);
          }
        },
        error: (error) => {
          this.isLoading=false;
          this.globalService.setGlobalErrorMessage(error);
        }
      });
    }else{
      this.isLoading = false;
    }
  }
}
