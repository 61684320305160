import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TransferredVehicleServiceService } from 'src/app/services/vehicles/transferred-vehicle.service';
import { GlobalService } from 'src/app/services/shared/global.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-view-transferred-vehicle',
  templateUrl: './view-transferred-vehicle.component.html',
  styleUrls: ['./view-transferred-vehicle.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ViewTransferredVehicleComponent implements OnInit{
  public isLoading: boolean = false;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public transfer_log_id: string | null = null;
  vehicle: any;
  photos: any[] = [];
  spare_parts: any[] = [];
  files: File[] = [];
  public userPermissions: string[] = [];
  selectedPhotos: any[] = []; // Array to store selected photos to delete
  public delPhotosPopupErrorMessage: string | null = null;
  @ViewChild('DeletePhotosModal') modalDeletePhotos!: ElementRef;

  constructor(
    private activatedRoute:ActivatedRoute,
    private transferredVehicleServiceService: TransferredVehicleServiceService,
    private globalService: GlobalService,
    private authService: AuthService,
  ){}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.transfer_log_id = param['get']('transfer_log_id')
    })

    if(this.transfer_log_id){
      this.isLoading=true;
      this.getVehicleData(this.transfer_log_id);
    }else{
      this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
    }

    this.userPermissions=this.authService.getPermissions();
  }

  /**
   * Get vehicle details
   * @param transfer_log_id 
   */
  getVehicleData(transfer_log_id: string): void{
    this.transferredVehicleServiceService.getTransferredVehicleDetails(transfer_log_id)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.isLoading=false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.vehicle = response;
          if(this.vehicle.status==='no_data' || this.vehicle.status.length===0){
            this.isLoading=false;
            this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again.";
          }else{
            this.getVehicleSpareParts(transfer_log_id); //Load spare parts
            this.getVehiclePhotos(transfer_log_id); //Load photos
          }
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  /**
   * Get vehicle photos
   * @param transfer_log_id 
   */
  getVehiclePhotos(transfer_log_id: string|null):void
  {
    this.transferredVehicleServiceService.fetchVehiclePhotos(transfer_log_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.isLoading=false;
          this.photos = response.data;
        }else if(response.status==='no_data'){
          this.isLoading=false;
          this.errorMessage=response.message;
        }else{
          this.isLoading=false;
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
        }
      },
      error: (error) => {
        this.isLoading=false;
        this.errorMessage=error;
      }
    });
  }

  getVehicleSpareParts(transfer_log_id: string|null):void {
    this.transferredVehicleServiceService.fetchVehicleSpareParts(transfer_log_id)
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.spare_parts = response.data;
        }else if(response.status==='no_data'){
          this.errorMessage=response.message;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  openDeletePhotosModal(){
    if (this.modalDeletePhotos) {
      (<any>$(this.modalDeletePhotos.nativeElement)).modal('show');
    }
  }

  closeModal():void {
    if (this.modalDeletePhotos) {
      (<any>$(this.modalDeletePhotos.nativeElement)).modal('hide');
    }
  }

  /**
   * Delete photos
   */
  deletePhotos(){
    if(this.selectedPhotos.length===0){
      this.delPhotosPopupErrorMessage="Please select photo(s) to delete!";
    }else{
      this.isLoading = true;

      this.transferredVehicleServiceService.deleteVehicleTransferPhotos(this.vehicle.data.vehicle_id,this.selectedPhotos)
      .subscribe({
        next: (response) => {
          if(response.status==='success'){
            this.globalService.setGlobalSuccessMessage(response.message);
            this.errorMessage=''; //Clear error message incase it's available
            this.closeModal(); //Close modal
            this.getVehiclePhotos(this.transfer_log_id); // Reload transfer photos
            this.selectedPhotos=[];  //Cleared photos selected for deletion
            this.photos=[]; //Clear photo gallery
          }else if(response.status==='error'){
            this.isLoading=false;
            this.delPhotosPopupErrorMessage = response.message;
            this.errorMessage=''; //Clear any caught error message incase it's available
          }else if(response.status==='partial_delete'){
            this.globalService.setGlobalErrorMessage(response.message);
            this.errorMessage=''; //Clear any caught error message incase it's available
            this.closeModal();  //Close modal
            // Reload vehicle photos
            this.getVehiclePhotos(this.transfer_log_id);
            //Cleared selected photos
            this.selectedPhotos=[];
          }else{
            this.isLoading=false;
            this.closeModal();  //Close modal
            this.errorMessage=response.message;
          }
        },
        error: (error) => {
          this.isLoading=false;
          this.closeModal();  //Close modal
          this.errorMessage=error;
        }
      });
    }   
  }

  // Select and unselect photos to delete
  checkboxChange(event: any, item: any) {
    if (event.target.checked) {
      // Add checked photo to the array
      this.selectedPhotos.push(item);
    } else {
      // Remove unchecked photo from the array
      const index = this.selectedPhotos.indexOf(item);
      if (index !== -1) {
        this.selectedPhotos.splice(index, 1);
      }
    }
  }
}
