<div *ngIf="isInProgress">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                        <li class="breadcrumb-item"><a routerLink="/sales/delivery-notes"><i class="fa fa-list-ul"></i>&nbsp;Delivery Notes List</a></li>
                        <li class="breadcrumb-item active">Print Delivery Note</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
</section>
<!-- /.content-header -->
<section class="content">
    <div class="container-fluid main-container">
        <div class="card">
            <div class="card-header">
                <div class="letter-head">
                    <img src="/assets/img/ahm_letter_head.jpg" width="1150" height="200" alt="Letter Head" *ngIf="deliveryData.data.consignee_id===1">
                    <img src="/assets/img/united_letter_head.jpg" width="1150" height="200" alt="Letter Head" *ngIf="deliveryData.data.consignee_id===2">
                </div>
                <div *ngIf="deliveryData.data.consignee_id===1">
                    <p class="contacts">P.O BOX 99032-80107 MOMBASA-KENYA</p>
                    <p class="contacts">MOBILE: 0722-411746/0748-222222</p>
                    <p class="contacts">EMAIL: sales&#64;alhusnainmotors.co.ke</p>
                </div>
                <div *ngIf="deliveryData.data.consignee_id===2">
                    <p class="contacts">Manor car bazaar | showroom no. 1</p>
                    <p class="contacts">P.O BOX 99032-80107 MOMBASA-KENYA</p>
                    <p class="contacts">MOBILE: 0702-115 693</p>
                </div>
                &nbsp;&nbsp;&nbsp;
                <p class="delivery-title">DELIVERY NOTE</p>

                <table class="table table-borderless">
                    <tr>
                        <td><span class="delivery-details">No.&nbsp;</span> {{deliveryData.data.delivery_no}}</td>
                        <td style="float: right;"><span class="delivery-details">Date:&nbsp;</span>{{deliveryData.data.delivery_date | date: 'dd-MM-yyyy'}}</td>
                    </tr>
                </table>
                <table class="table table-bordered custom-table">
                    <tr>
                        <td><span class="header-labels">Name:</span></td>
                        <td>{{deliveryData.data.customer_name}}</td>
                        <td><span class="header-labels">ID No.</span></td>
                        <td>{{deliveryData.data.id_no}}</td>
                    </tr>
                    <tr>
                        <td><span class="header-labels">Address</span></td>
                        <td>{{deliveryData.data.customer_address}}</td>
                        <td><span class="header-labels">Phone No.</span></td>
                        <td>{{deliveryData.data.phone_no}}</td>
                    </tr>
                    <tr>
                        <td><span class="header-labels">Chassis No.</span></td>
                        <td>{{deliveryData.data.chassis_no}}</td>
                        <td><span class="header-labels">Engine No.</span></td>
                        <td>{{deliveryData.data.engine_no}}</td>
                    </tr>
                    <tr>
                        <td><span class="header-labels">Reg. No.</span></td>
                        <td>{{deliveryData.data.reg_no}}</td>
                        <td><span class="header-labels">Colour</span></td>
                        <td>{{deliveryData.data.colour}}</td>
                    </tr>
                    <tr>
                        <td><span class="header-labels">Make & Model</span></td>
                        <td>{{deliveryData.data.make}} {{deliveryData.data.model}}</td>
                        <td><span class="header-labels">C.C</span></td>
                        <td>{{deliveryData.data.engine_cc}}</td>
                    </tr>
                </table>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <span style="color:green;">Accessories</span>
                <div class="delivery-note-container">
                    <div class="delivery-note-column" *ngFor="let column of delivery_note_columns">
                        <span *ngFor="let delivery_note_item of column" class="delivery-note-item">
                            {{ delivery_note_item.name }}
                        </span>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
                <p class="terms">The fore-said motor vehicle is sold on the basis "As it is" and the seller does not give any guarantee whatsoever. 
                    The buyer has confirmed by signing this Delivery note that He/She has taken possession of the foresaid motor vehicle or basis "AS IT IS" 
                    and upon being satisfied that the motor vehicle is in proper condition. After delivery if the vehicle is involved in accident 
                    or stolen or burnt it will be the responsibility of buyer. It's the responsibility of the buyer to ensure the vehicle has valid insurance before getting on the road</p>
                <table class="table table-borderless">
                    <tr>
                        <td>
                            <span class="footer-labels">Delivered By Name:</span>&nbsp;{{deliveryData.data.delivery_by}}
                            <div class="signature-line"></div>
                        </td>
                        <td style="float: right;">
                            <span class="footer-labels">Accepted By Name:</span>&nbsp;{{deliveryData.data.customer_name}}
                            <div class="signature-line"></div>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- /.card-footer -->
        </div>
    </div>
</section>