import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDevices'
})
export class SearchDevicesPipe implements PipeTransform {

  transform(devices: any, searchText: string) {
    
    if (!searchText) {
      return devices; // Return the original array if search text is empty
    }
  
    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison
  
    return devices.filter((devices: any) => 
      (devices.user_fullname && devices.user_fullname.toLowerCase().includes(searchText))||
      (devices.device_id && devices.device_id.toLowerCase().includes(searchText))
    );
  }

}
