import { Component, OnInit } from '@angular/core';
import { StockService } from 'src/app/services/stock/stock.service';

@Component({
  selector: 'app-view-returned-sales',
  templateUrl: './view-returned-sales.component.html',
  styleUrls: ['./view-returned-sales.component.css']
})
export class ViewReturnedSalesComponent implements OnInit{
  page: number = 1;
  count: number = 0;
  public errorMessage: string | null = null;
  public isInProgress: boolean = false;
  returned_vehicles: any[] = [];
  searchText: string = '';

  ngOnInit(): void {
    this.isInProgress=true;
    this.fetchReturnedVehicles();
  }
   constructor(
    private stockService:StockService,
   ){}

   fetchReturnedVehicles():void{
    this.stockService.getReturnedVehicles()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.returned_vehicles = response.data;
          this.isInProgress=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isInProgress=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isInProgress=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isInProgress=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    this.page = 1; // Reset to page 1 after applying filter
  }
}
