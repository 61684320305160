<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
           
            <div class="col-sm-6">
              
            </div><!-- /.col -->
           
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Manage Devices</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
        <div class="row">
            <div class="col-md-8">
                <div class="row">
                   
                </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                  <div class="col">
                      <input type="text" class="form-control" placeholder="Type User Name or Device ID here to search" (input)="onSearchTextChange($event)">
                  </div>
              </div>
            </div>
        </div>
    </div>
    </section>
    <!-- /.content-header -->
    <section class="content">
      <div class="container-fluid">
      <div class="card">
          <div class="card-header">
            <h3 class="card-title">Manage Devices</h3>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <!--Error Message-->
            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
            </div>
            <!--Success Message-->
            <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{successMessage}}
            </div>
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                    <th>User Name</th>
                    <th>Device ID</th>
                    <th>Created On</th>
                    <th>Last Use</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let device of devices | searchDevices: searchText | paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                      <td>{{device.user_fullname}}</td>
                      <td>{{device.device_id}}</td>
                      <td>{{device.created_at | date: 'dd-MM-yyyy'}}</td>
                      <td>{{device.updated_at | timeAgo}}</td>
                      <td>
                        <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button"
                                data-toggle="dropdown">Action
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" (click)="openModal(device.id,device.device_id)"><i class="fa fa-edit"></i>&nbsp;Edit</a></li>
                                <li><a class="dropdown-item" href="#" (click)="delete($event,device.id)"><i class="fa fa-trash"></i>&nbsp;Delete</a></li>
                            </ul>
                        </div>
                      </td>
                  </tr>
              </tbody>
            </table>
            <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </section>
    <!--Modal-->
    <div class="modal fade" #UpdateDevice tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update Device</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form [formGroup]="form" (ngSubmit)="updateDevice()">
              <div class="modal-body">
                  <div class="form-group">
                      <label>Device ID:<span style="color:red;">*</span></label>
                      <div class="input-group mb-3">
                          <div class="custom-file">
                            <input type="text" formControlName="new_device_id" class="form-control" placeholder="Device ID">
                          </div>
                      </div>
      
                      <span *ngIf="submitted && form.get('new_device_id')?.hasError('required')" style="color:red"> <strong>Please enter Device ID to proceed!</strong></span>
                  </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button class="btn btn-success" type="button" *ngIf="isLoading; else showUpdateBtn" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Processing...
                </button>
          
                <ng-template #showUpdateBtn>
                    <button type="submit" class="btn btn-success"><i class="fa fa-save" aria-hidden="true">&nbsp;</i>Update</button>
                </ng-template>
              </div>
            </form>
          </div>
        </div>
      </div>
