<div *ngIf="isInProgress">
    <app-spinner></app-spinner>
</div>
  
  <!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                <li class="breadcrumb-item active">Returned Sales</li>
            </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <div class="row">
        <div class="col-md-8">

        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col">
                    <input type="text" class="form-control" placeholder="Search by Make, Model, Chassis Number" (input)="onSearchTextChange($event)">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /.content-header -->
<section class="content">
<div class="container-fluid">
    <div class="card">
    <div class="card-header">
        <h3 class="card-title">Returned Sales</h3>
        <!--Error Message-->
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body" style="overflow-x: auto; overflow-y: auto; white-space: nowrap; height: 650px;">
            <!--Error messages here-->
        <table id="example1" class="table table-bordered table-hover table-striped">
        <thead>
        <tr>
            <th>Make</th>
            <th>Model</th>
            <th>Chassis No.</th>
            <th>Sold From</th>
            <th>Returned To</th>
            <th>Returned On</th>
            <th>Comment</th>
            <th>Received By</th>
        </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vehicle of returned_vehicles  | searchVehicle: searchText| paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                <td>{{vehicle.make}}</td>
                <td>{{vehicle.model}}</td>
                <td>{{vehicle.chassis_no}}</td>
                <td>{{vehicle.from_location_name}} - {{vehicle.from_branch_name}}</td>
                <td>{{vehicle.to_location_name}} - {{vehicle.to_branch_name}}</td>
                <td>{{vehicle.date_returned | date: 'dd-MM-yyyy'}}</td>
                <td>{{vehicle.comment}}</td>
                <td>{{vehicle.received_by}}</td>
            </tr>
        </tbody>
        </table>
        <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
    </div>
    <!-- /.card-body -->
    </div>
</div>
</section>