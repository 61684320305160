import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Accept': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }
  
  /**
   * Get all stock
   * @returns Array
   */
  getAllStock(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/view-all-stock`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get available stock
   * @returns Array
   */
  getAllAvailableStock(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/view-all-available-stock`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get stock en route
   * @returns Array
   */
  getStockEnroute(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/view-stock-enroute`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get stock at CFS
   * @returns Array
   */
  getStockAtCFS(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/view-stock-at-cfs`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter all stock by location
   * @returns Array
   */
  filterAllStockByLocation(locationId: string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/filter-all-stock-by-location/${locationId}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter all stock by branch
   * @returns Array
   */
  filterAllStockByBranch(branchId: string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/filter-all-stock-by-branch/${branchId}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Filter available stock by location
   * @returns Array
   */
   filterAvailableStockByLocation(locationId: string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/filter-avail-stock-by-location/${locationId}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Filter available stock by branch
   * @returns Array
   */
  filterAvailableStockByBranch(branchId: string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/filter-avail-stock-by-branch/${branchId}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get sold cars
   * @returns Array
   */
  getSoldCars(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/get-sold-vehicles`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

   /**
   * Get sold cars
   * @returns Array
   */
   getVehicleExpenseDetails(vehicle_id:string): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/view-vehicle-expense-details/${vehicle_id}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get sold vehicle details
   * @param vehicle_id 
   */
  getVehicleDetails(vehicle_id: string){

    let apiURL:string=`${this.baseURL}/v1/sold-vehicle/get-details/${vehicle_id}`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Get branches with locations
   * @returns Array
   */
  public fetchBranchesWithLocation():Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/shared/branches-with-location`;

    return this.http.get<ApiResponse>(apiURL).pipe(catchError(this.errorHandler.handleError));

  }

  /**
   * Return sold vehicle
   * @param form_data 
   * @param vehicle_id 
   * @returns array
   */
  returnSoldVehicle(form_data:any,vehicle_id: string|null):Observable<any>{
    
    let apiURL:string=`${this.baseURL}/v1/sold-vehicle/process-return/${vehicle_id}`;

    return this.http.post(apiURL,form_data, { headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }

  /**
   * Get returned vehicles
   * @returns Array
   */
  getReturnedVehicles(): Observable<ApiResponse>{

    let apiURL:string=`${this.baseURL}/v1/sales/returned-vehicles`;

    return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
  }
}
