import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-default-dashboard',
  templateUrl: './default-dashboard.component.html',
  styleUrls: ['./default-dashboard.component.css']
})
export class DefaultDashboardComponent implements OnInit{
  public userPermissions: string[] = [];

  constructor(
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
     this.userPermissions=this.authService.getPermissions();
  }

}
