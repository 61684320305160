import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/IApiResponse';
import { Observable, catchError } from 'rxjs';
import { ConfigErrorHandler } from 'src/app/shared/config.errorhandler';

@Injectable({
  providedIn: 'root'
})
export class ReceivedVehicleService {
  private baseURL = environment.apiURL;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private errorHandler: ConfigErrorHandler
  ) { }

   /**
   * Get transferred vehicle record
   * @param receive_log_id 
   */
   getReceivedVehicleDetails(receive_log_id: string)
   {
 
     let apiURL:string=`${this.baseURL}/v1/get-received-vehicle-details/${receive_log_id}`;
 
     return this.http.get(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
 
   }
 
   /**
  * Get vehicle photos
  * @param receive_log_id 
  * @returns Observable<ApiResponse>
  */
   fetchVehiclePhotos(receive_log_id: string|null): Observable<ApiResponse>
   {
     let apiURL:string=`${this.baseURL}/v1/get-received-vehicle-photos/${receive_log_id}`;
 
     return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
   }

    /**
  * Get vehicle spare parts
  * @param receive_log_id 
  * @returns Observable<ApiResponse>
  */
    fetchVehicleSpareParts(receive_log_id: string|null): Observable<ApiResponse>
    {
      let apiURL:string=`${this.baseURL}/v1/get-received-vehicle-spare-parts/${receive_log_id}`;
  
      return this.http.get<ApiResponse>(apiURL,{ headers: this.headers }).pipe(catchError(this.errorHandler.handleError));
    }

    /**
   * Delete vehicle transfer photos 
   * @param vehicleId
   * @param selectedPhotos 
   * @returns 
   */
    deleteVehicleReceivingPhotos(vehicleId:string|null,selectedPhotos: any[]): Observable<any> {

      let apiURL:string=`${this.baseURL}/v1/delete-vehicle-receiving-photos`;
  
      const data = {
        vehicle_id:vehicleId,
        selected_photos: selectedPhotos
      };
  
      return this.http.post<any>(apiURL, data);
    }
}
