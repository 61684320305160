import { Component, OnInit } from '@angular/core';
import { TransferredVehiclesInTransitService } from 'src/app/services/vehicles/transferred-vehicles-in-transit.service';

@Component({
  selector: 'app-vehicle-transfer-in-transit',
  templateUrl: './vehicle-transfer-in-transit.component.html',
  styleUrls: ['./vehicle-transfer-in-transit.component.css']
})
export class VehicleTransferInTransitComponent implements OnInit{
  public errorMessage: string | null = null;
  vehicles: any[] = [];
  page: number = 1;
  count: number = 0;
  searchText: string = '';
  public isLoading: boolean = false;

  constructor(
    private transferredVehiclesInTransitService: TransferredVehiclesInTransitService,
  ){}

  ngOnInit(): void {
    this.isLoading=true;
   this.fetchVehiclesInTransit();
  }

  fetchVehiclesInTransit():void{
    this.transferredVehiclesInTransitService.getTransferredVehiclesInTransit()
    .subscribe({
      next: (response) => {
        if(response.status==='success'){
          this.vehicles = response.data;
          this.isLoading=false;
        }else if(response.status==='no_data'){
           this.errorMessage=response.message;
           this.isLoading=false;
        }else{
          this.errorMessage="Sorry, a problem was encountered while processing your request. Please refresh the page and try again!";
          this.isLoading=false;
        }
      },
      error: (error) => {
        this.errorMessage=error;
        this.isLoading=false;
      }
    });
  }

  pageChangeEvent(event: number){
    this.page = event;
    this.fetchVehiclesInTransit();
  }

  onSearchTextChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }
}
