<div *ngIf="isInProgress">
    <app-spinner></app-spinner>
</div>
  
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/"><i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;Dashboard</a></li>
                    <li class="breadcrumb-item active">Delivery Notes</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <div class="row">
        <div class="col-md-8">
           
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col">
                    <input type="text" class="form-control" placeholder="Search by Make, Model, Chassis No., Customer Name or Delivered By" (input)="onSearchTextChange($event)">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /.content-header -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Delivery Notes&nbsp;&nbsp;&nbsp;Count: {{rowCount}}</h3>
                <!--Error Message-->
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <!--Success Message-->
                <div class="alert alert-success" role="alert" *ngIf="successMessage">
                    {{successMessage}}
                </div>
                    <!--Warning Message-->
                <div class="alert alert-warning" role="alert" *ngIf="warningMessage">
                    {{warningMessage}}
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="overflow-x: auto; white-space: nowrap;">
                <!--Error messages here-->
                <table id="example1" class="table table-bordered table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Delivery No.</th>
                            <th>Make</th>
                            <th>Model</th>
                            <th>Chassis No.</th>
                            <th>Colour</th>
                            <th>Customer Name</th>
                            <th>Delivered By</th>
                            <th>Comment</th>
                            <th *ngIf="userPermissions.includes('print-del-nt') || userPermissions.includes('attach-sgn-del-nt') || userPermissions.includes('edit-del-nt-cust-info')"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let delivery_note of delivery_notes  | searchDeliveryNotes: searchText| paginate: { itemsPerPage: 10, currentPage: page, totalItems: count }">
                            <td>{{delivery_note.delivery_date | date: 'dd-MM-yyyy'}}</td>
                            <td>{{delivery_note.delivery_no}}</td>
                            <td>{{delivery_note.make}}</td>
                            <td>{{delivery_note.model}}</td>
                            <td>{{delivery_note.chassis_no}}</td>
                            <td>{{delivery_note.colour}}</td>
                            <td>{{delivery_note.customer_name}}</td>
                            <td>{{delivery_note.delivery_by}}</td>
                            <td>{{delivery_note.comment}}</td>
                            <td *ngIf="userPermissions.includes('print-del-nt') || userPermissions.includes('attach-sgn-del-nt') || userPermissions.includes('edit-del-nt-cust-info')">
                                <div class="dropdown">
                                    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Action<span class="caret"></span></button>
                                    <ul class="dropdown-menu">
                                        <li *ngIf="userPermissions.includes('print-del-nt')"><a class="dropdown-item" routerLink="/sales/print-delivery-note/{{delivery_note.delivery_uuid}}">Print</a></li>
                                        <li *ngIf="userPermissions.includes('attach-sgn-del-nt')"><a (click)="openUploadModal(delivery_note.delivery_uuid,delivery_note.vehicle_id)" class="dropdown-item">Attach Signed Delivery Note</a></li>
                                        <li *ngIf="userPermissions.includes('edit-del-nt-cust-info')"><a (click)="openModal(delivery_note.delivery_uuid)" class="dropdown-item">Edit Customer Info</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls (pageChange)="pageChangeEvent($event)"></pagination-controls>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>

 <!-- Update customer info Modal -->
 <div class="modal fade" #UpdateCustomerInfoModal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Update Customer Info</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!--Error message-->
            <div class="alert alert-danger" role="alert" *ngIf="popupErrorMessage">
                {{popupErrorMessage}}
            </div>

             <!--Warning message-->
             <div class="alert alert-warning" role="alert" *ngIf="popupWarningMessage">
                {{popupWarningMessage}}
            </div>

            <form [formGroup]="form" (ngSubmit)="handleSubmit()">
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row clearfix">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Customer Name:<span style="color:red;">*</span></label>
                                    <input type="text" formControlName="customer_name" class="form-control" placeholder="Customer Name" [(ngModel)]="customerData?.data.customer_name">
                                    <span *ngIf="submitted && form.get('customer_name')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>ID No.:<span style="color:red;">*</span></label>
                                    <input type="text" formControlName="id_no" class="form-control" placeholder="ID No." [(ngModel)]="customerData?.data.id_no">
                                    <span *ngIf="submitted && form.get('id_no')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Phone No.:<span style="color:red;">*</span></label>
                                    <input type="text" formControlName="phone_no" class="form-control" placeholder="Phone No." [(ngModel)]="customerData?.data.phone_no">
                                    <span *ngIf="submitted && form.get('phone_no')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Customer Address:<span style="color:red;">*</span></label>
                                    <input type="text" formControlName="customer_address" class="form-control" placeholder="Customer Address" [(ngModel)]="customerData?.data.customer_address">
                                    <span *ngIf="submitted && form.get('customer_address')?.hasError('required')" style="color:red"> <strong>This field is required!</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" #closeModal class="btn btn-danger" data-dismiss="modal"><i class="fa fa-window-close" aria-hidden="true"></i>&nbsp;Cancel</button>

                    <button class="btn btn-success" type="button" *ngIf="isProcessing; else showSaveBtn" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Processing...
                    </button>

                    <ng-template #showSaveBtn>
                        <button type="submit" class="btn btn-success" ><i class="fa fa-save"
                            aria-hidden="true">&nbsp;</i>Update</button>
                    </ng-template>
                </div>
            </form>
        </div>
    </div>
</div>

<!--Attach signed delivery note modal-->
<div class="modal fade" #AttachSignedDeliveryNoteModal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Attach Signed Delivery Note</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!--Error message-->
            <div class="alert alert-danger" role="alert" *ngIf="popupErrorMessage">
                {{popupErrorMessage}}
            </div>

             <!--Warning message-->
             <div class="alert alert-warning" role="alert" *ngIf="popupWarningMessage">
                {{popupWarningMessage}}
            </div>

            <form [formGroup]="attachSignedDeliveryNoteForm" (ngSubmit)="handleUpload()">
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row clearfix">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Attachment:<span style="color:red;">*</span></label>
                                    <div class="input-group mb-3">
                                        <div class="custom-file">
                                          <input type="file" formControlName="signed_delivery_note_attachment" accept="application/pdf" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" (change)="onDeliveryNoteFileSelected($event)">
                                          <label class="custom-file-label" for="inputGroupFile01">{{ signedDeliveryNoteFileName }}</label>
                                        </div>
                                    </div>
        
                                    <span *ngIf="submitted && attachSignedDeliveryNoteForm.get('signed_delivery_note_attachment')?.hasError('required')" style="color:red"> <strong>Please attach a signed copy of delivery note!</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" #closeUploadModal class="btn btn-danger" data-dismiss="modal"><i class="fa fa-window-close" aria-hidden="true"></i>&nbsp;Cancel</button>

                    <button class="btn btn-success" type="button" *ngIf="isProcessing; else showUploadBtn" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Processing...
                    </button>

                    <ng-template #showUploadBtn>
                        <button type="submit" class="btn btn-success" ><i class="fa fa-save"
                            aria-hidden="true">&nbsp;</i>Upload</button>
                    </ng-template>
                </div>
            </form>
        </div>
    </div>
</div>